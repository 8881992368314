export type Class<T extends object = object> = new () => T;

export type EmptyObject = { [k in never]: never };

export type FilteredValue<TObj extends object, TFilter> = Value<PartialFilter<TObj, TFilter>>;
export type PartialFilter<TObj extends object, TFilter> = {
  [P in keyof TObj as TObj[P] extends TFilter ? P : never]: TObj[P];
};

export type KeycloakPaths = Record<'certs' | 'logout' | 'token', string>;

export const LOG_LEVELS = { debug: 7, error: 3, info: 6, warning: 4 } as const;
export type LogLevel = keyof typeof LOG_LEVELS;

export type ObjectKey = number | string | symbol;

export const PLACE_PROVIDERS = ['google', 'pelias'] as const;
export type PlaceProvider = (typeof PLACE_PROVIDERS)[number];
export type Place = { id: string; provider: PlaceProvider; formatted: string };
export type PlaceDetails = Place & {
  country?: string;
  countryCode?: string;
  latitude: number;
  locality?: string;
  longitude: number;
  postalCode?: string;
  route?: string;
  streetNumber?: string;
};

export type Value<T extends object> = T[keyof T];
export type ValueProperty<TObj extends object, K extends ObjectKey> = ValueWith<TObj, K>[keyof ValueWith<TObj, K> & K];
export type ValueWith<TObj extends object, K extends ObjectKey> = FilteredValue<TObj, { [P in K]: unknown }>;

export const LANGS = ['de', 'en', 'fr', 'it'] as const;
export type Lang = (typeof LANGS)[number];
export type LangParams = { lang: Lang };
export type InternationalizedPathname = { lang: Lang | null; pathname: string };

export type Translations = Record<Lang, string>;
