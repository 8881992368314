import { Lang } from './types';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const ALPHABET_SHUFFLED = 'JIODPQSANRKTMGZLBWCVEHFXYU';

export const CURRENCY = 'CHF';
export const DEFAULT_LANG: Lang = 'en';
export const SWISS_PHONE_CODE = '+41';

export const EMPTY_FUNCTION = () => {
  // Empty function
};

export const EMPTY_ASYNC_FUNCTION = async () => {
  // Empty async function
};
